body {
  background-color: #FFF;
}

.loader { 
    width: 50px;
    height: 50px;
    background: conic-gradient(#ffffff00, #bdffc200, #333C);
    border-radius: 50%;
    margin: 20px auto;
    padding: 10px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    &::after {
        content: " ";
        display: block;
        height: 30px;
        width: 30px;
        background-color: white;
        margin: 0 auto;
        border-radius: 50%;
    }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

