@import "base.scss";

* { font-family:Verdana, Geneva, Tahoma, sans-serif; }

#dark, .dark { color: $wht; }
#lite, .lite { color: $blk; }
#light, .light {
  .tx0 { color: $blk; }
}
#dark, .dark {
  .tx0 { color: $wht; }
}
.tx1 { color: $clr1; }
.tx2 { color: $clr2; }
.tx3 { color: $clr3; }
.tx4 { color: $clr4; }
.tx5 { color: $clr5; }

.ltx { font-size: 2rem; }

@mixin tgred ($c1: $clr1, $c2: $clr2, $c3: $clr3, $c4: white) {
    background: linear-gradient(to right, $c1, $c2, $c3, $c4); font-weight: 900; background-clip: text; color: transparent; -webkit-text-stroke: 2px rgba($blk, 20%);
}

.txt2 { @extend .ltx; -webkit-text-stroke: 1px #0006; font-weight: 900;  }
.gtxt1 { @extend .ltx; @include tgred(red, skyblue, green, red); line-height: 2.5rem; font-size: 2.5rem; }

.tx3d {
    font-size: 2rem;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191,
    1px 2px 6px rgba(16,16,16,0.4), 1px 15px 10px rgba(16,16,16,0.2), 1px 16px 35px rgba(16,16,16,0.2),1px 20px 60px rgba(16,16,16,0.4); }


.cd6 { font-size: 2.1rem; font-weight: 100; }

.txt3 { font-size: 3rem; text-transform: capitalize; font-weight: 100; line-height: 3rem; margin-top: 3rem; }
.txt4 { font-size: 3rem; line-height: 3rem; margin-top: 3rem;
    &::first-letter { font-weight: 900; color: $wht; position: absolute !important; padding: 0 15px; letter-spacing: 0; background-color: cadetblue; }
}

@mixin lln($lw: 1px, $st: solid, $tx: $blk, $mt: 20px) {
    &::before { content: " "; line-height: 0; border: none; border-top: $lw $st lighten($tx, 30); width: 90%; height: 0; margin: $mt 10px; margin-right: 10px; }
}
@mixin rln($lw:1px, $st: solid, $tx: $blk, $mt: 20px) {
    &::after { content: " "; line-height: 0; border: none; border-top: $lw $st lighten($tx, 30); width: 90%; height: 0; margin: $mt 10px; margin-left: 10px; }
}

@mixin lntx ($tx: $blk, $fs: 1.7rem, $st: solid, $lw: 1px) {
    display: flex; white-space: nowrap; font-size: $fs; color: $tx;
    @include lln($lw, $st, $tx, $fs);
    @include rln($lw, $st, $tx, $fs);
}
@mixin letx ($tx: $blk, $fs: 1.7rem, $st: solid, $lw: 1px) {
    display: flex; white-space: nowrap; font-size: $fs; color: $tx;
    @include rln($lw, $st, $tx, $fs);
}

.ltx1{ @include lntx($blk, 1.7rem, solid, 1px); }

#light, .light {
    .txt5 { @include letx($blk, 3rem, solid, 2px);}
}
#dark, .dark {
    .txt5 { @include letx($wht, 3rem, solid, 2px);}
    .list-group-item { color: $wht; }
}

.tdot1 {
    display: flex; white-space: nowrap; font-size: 1.5rem;
    @include lln(2px, dashed, $blk, 20px);
}

.tdot2 {
    display: flex; white-space: nowrap; font-size: 1.5rem;
    @include rln(2px, dotted, $blk, 20px);
}

.ptx1 {
    font-size: 1.6rem;
    margin: 0;
}


.sptx {
    label {margin: -10px 10px;
        font-size: 1.2rem !important;
        line-height: 0;
        color: #8080804a !important;
        position: absolute;
        z-index: 0;}
    input {
        z-index: 2;
        position: relative;
    }
}

// 

#dark {
    .list-group-item, input, .form-control { background: rgba($blk, 20%); color: white; }
    .table * { color: white; }    
}