@import 'base.scss';

@mixin cardn ($cl: $clr2, $tx: $blk) {
    background-color: rgba($cl, 90%);
    color: rgba($tx, 90%);
    box-shadow: 5px 5px 25px -5px rgba($blk, 40%);
}
@mixin cardBas ($cl: $clr2, $tx: $clr1) {
    background-color: rgba($cl, 90%);
    color: $tx;
    box-shadow: 5px 5px 20px rgba(darken($cl, 20), 80%);
    // border: 2px dotted lighten($cl, 20);    
}

@mixin dash ($cl: $clr2) {
    padding: 20px;
    outline: 2px dashed darken(rgba($cl, 40%), 20);
    outline-offset: -8px;
    &:before {
        content:""; position: absolute;
        top:1px; left:1px; right:1px; bottom:1px;
        outline: 2px dashed rgba($wht, 40%); outline-offset: -8px;
    }
}

#dark, .dark {
    .crd1 { @include cardn(rgba($blk, 80%), $wht); }
    .card1 { @include cardBas($clr1, $wht); @include dash($clr1); }
    .card2 { @include cardBas($clr2, $wht); }
    .card3 { @include cardBas($clr3, $wht); }
    .card4 { @include cardBas($clr4, $wht); }
    .card5 { @include cardBas($clr5, $wht); }        
}
#light, .light {
    .crd1 { @include cardn($wht); }
    .card1 { @include cardBas($clr1, $blk); @include dash($clr1); }
    .card2 { @include cardBas($clr2, $blk); }
    .card3 { @include cardBas($clr3, $blk); }
    .card4 { @include cardBas($clr4, $blk); }
    .card5 { @include cardBas($clr5, $blk); }        
}

@mixin icardBas ($cl: $clr2, $tx: $clr1) {
    background-color: $cl;
    color: $tx;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(darken($cl, 50), 50%);
    background-size: cover;
    * { backdrop-filter: blur(5px); }
}


#light, .light {
    .icard1 { @include icardBas($clr1, $blk); }
    .icard2 { @include icardBas($clr2, $blk); }
    .icard3 { @include icardBas($clr3, $blk); }
    .icard4 { @include icardBas($clr4, $blk); }
    .icard5 { @include icardBas($clr5, $blk); }
}
#dark, .dark {
    .icard1 { @include icardBas($clr1, $wht); }
    .icard2 { @include icardBas($clr2, $wht); }
    .icard3 { @include icardBas($clr3, $wht); }
    .icard4 { @include icardBas($clr4, $wht); }
    .icard5 { @include icardBas($clr5, $wht); }
}